@import-normalize;

@import url('https://fonts.googleapis.com/css?family=Julius+Sans+One|Pavanam|Thasadith&display=swap');
/*
font-family: 'Julius Sans One', sans-serif;
font-family: 'Pavanam', sans-serif;
font-family: 'Thasadith', sans-serif; */

body {
  margin: 0;
  background-color: #f5f4f0;
  font-family: -apple-system, 'Pavanam', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 12px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
